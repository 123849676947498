.upload-pic-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* login work */
.login-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-bg {
  width: 100%;
  height: 100vh;
  filter: brightness(0.6);
}
.login-box {
  width: 50%;
  color: #fff;
  max-width: 500px;
  position: absolute;
  border-radius: 10px;
  background: rgb(0, 81, 81);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.login-body {
  padding: 40px;
  display: flex;
  max-width: 500px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-foot {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid teal;
}
.emailBox {
  width: 100%;
  position: relative;
}
.loginEmailInpt {
  width: 100%;
  height: 40px;
  color: white;
  font-size: 16px;
  margin-top: 10px;
  padding-left: 10px;
  margin-bottom: 30px;
  background-color: transparent;
  border-bottom: 1px solid teal;
}
.loginEmailInpt::placeholder,
.loginPswInpt::placeholder {
  color: gainsboro;
}
.loginPswInpt {
  width: 100%;
  height: 40px;
  color: white;
  font-size: 16px;
  padding-left: 10px;
  margin-bottom: 30px;
  background-color: transparent;
  border-bottom: 1px solid teal;
}

.loginButStyle {
  width: 80%;
  height: 45px;
  cursor: pointer;
  color: teal !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  background: #fff !important;
  border-radius: 100px !important;
}

.policyTxt {
  width: 80%;
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

.loginPswIcon {
  cursor: pointer;
  top: 0 !important;
  right: 0 !important;
  color: #fff !important;
  position: absolute !important;
}

input:-webkit-autofill {
  border: none;
  -webkit-text-fill-color: #fff;
  border-bottom: 1px solid teal;
  -webkit-box-shadow: 0 0 0 30px rgb(0, 81, 81) inset !important;
}

/* Sign up work */

.signUp-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signUp-bg {
  width: 100%;
  height: 100vh;
  filter: brightness(0.6);
}
.signUp-box {
  width: 50%;
  color: #fff;
  max-width: 800px;
  position: absolute;
  border-radius: 10px;
  background: rgb(0, 81, 81);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.signUp-body {
  padding: 40px;
  display: flex;
  max-width: 800px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.signUpButStyle {
  width: 80%;
  height: 45px;
  cursor: pointer;
  color: teal !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  background: #fff !important;
  border-radius: 100px !important;
}
.signUpswIcon {
  cursor: pointer;
  top: 0 !important;
  right: 0 !important;
  color: #fff !important;
  position: absolute !important;
}
.signUpStyle input::placeholder {
  color: gainsboro;
}
.hizzyLogo {
  height: 80px;
  margin-bottom: 30px;
}
.signUpStyle {
  width: 100%;
  height: 40px;
  display: flex;
  color: white;
  font-size: 16px;
  position: relative;
  flex-direction: row;
  margin-bottom: 30px;
  background-color: transparent;
  justify-content: space-between;
}
.signUpStyle2 {
  width: 46%;
  height: 40px;
  display: flex;
  color: white;
  font-size: 16px;
  position: relative;
  flex-direction: row;
  margin-bottom: 30px;
  background-color: transparent;
  justify-content: space-between;
}
.signUpStyle input {
  width: 46%;
  height: 40px;
  color: white;
  font-size: 18px;
  padding-left: 10px;
  margin-bottom: 30px;
  background-color: transparent;
  border-bottom: 1px solid teal;
}
.passwordInptContainer {
  position: relative;
  width: -webkit-fill-available;
}
