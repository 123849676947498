.dashboard-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 1px 5px 18px #e9e8e8;
}
.test {
  color: "green" !important;
  background-color: rgb(255, 255, 255);
}
.dashboard-header-image {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
  width: 50%;
  height: 100%;
  background-image: url(../assets/images/dashboardHome.png);
  background-size: cover;
  border-radius: 20px 20px 20px 20px;
}

.dashboard-header-text {
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 50%;
}
.dashboard-image {
  width: 100%;
  height: 100%;
  resize: contain;
  border-radius: 50px;
  object-fit: cover;
}

.header-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

::selection {
  background-color: #c3cfe2;
}

.text {
  text-transform: uppercase;
  background: linear-gradient(to right, #fca779 0%, #fe7a7e 200%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: "bold";
}

#modal-box {
  border-radius: 20px;
  border: none;
}

#fab {
}

@media screen and (max-width: 768px) {
  .dashboard-header-wrapper {
    height: 300px;
    display: flex;
    flex-direction: column;
  }
  .dashboard-header-image {
    width: 100%;
    border-radius: 5px;
  }
  .dashboard-header-text {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;
    font-size: 13px;
    text-align: center;
    align-items: center;
    width: 100%;
  }
  .text {
    margin-bottom: -30px;
    font-size: 18px;
  }
  .fullWidthOnMobile {
    width: 100% !important;
  }
  .col-6 {
    padding: 3px !important;
  }

  #modal-box {
    width: 90%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    border-radius: 20px;
    border: none;
  }

  #fab {
    top: 85vh !important;
  }
}
