::selection { background-color: #C3CFE2; }



.text {
	text-transform: uppercase;
	background: linear-gradient(to right, #01B5E7 0%, #2C2974 200%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 'bold';
}
