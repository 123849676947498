* {
  box-sizing: border-box;
}
.notificationHeading {
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
}
.notificationDeleteBtn {
  position: absolute;
  font-size: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
