.card-user-image {
}
.card-user-img {
  border-radius: 5px;
  height: 250px;
  width: 100%;
}
.author {
  margin-top: -150px;
  text-align: center;
  text-transform: none;
}
.title {
  color: #55adff;
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}
.description {
  margin-bottom: 5px;
}
.text-center {
  text-align: center;
}
.avatar {
  width: 12vw;
  height: 12vw;
  object-fit: cover;
  border-radius: 500px;
}
.card-body {
  min-height: 240px;
}
hr {
  margin: 5px 15px 15px;
}
.card-body + .card-footer {
  padding-top: 0;
}
.card-footer h5 {
  font-size: 1.25em;
  margin-bottom: 0;
}
.button-container {
  margin-bottom: 6px;
  text-align: center;
}

.heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.row {
  display: flex;
  padding: 18px;
  justify-content: space-between;
}
.formInput {
  width: 49%;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.profileRow {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.profileCol {
  width: 49%;
}

@media screen and (max-width: 768px) {
  .profileRow {
    flex-direction: column;
  }
  .profileCol {
    width: 100%;
  }
  .author {
    margin-top: -100px;
    text-align: center;
    text-transform: none;
  }
}
