
    .App{
        /* width: 200px; */
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* .outlined-basic-multiline{
        height: 200px;
    } */


@media screen and (max-width: 768px) {
 
    .App{
        /* width: 200px; */
        /* background-color: green; */
        width: 100%;
    }

}